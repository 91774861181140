import { PayloadAction } from "@reduxjs/toolkit";
import { LayoutModel } from "../domain/layout";

export const reducers = {

  toggleMenuIsOpen(state: LayoutModel, action: PayloadAction<{ value?: boolean | null }>) {
    if (typeof action.payload.value !== 'boolean') {
      state.sideBarSubMenuOpen = !state.sideBarSubMenuOpen
    } else {
      state.sideBarSubMenuOpen = action.payload.value
    }
  },

};
