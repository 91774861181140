import { t } from "i18next";

export interface ValidateObjectType {
  [key: string]: string | undefined;
  email?: string;
  phone?: string;
  username?: string;
  inn?: string;
  number?: string;
  date?: string;
  message?: string;
  repeadPassword?: string;
  fio?: string;
  login?: string;
  companyName?: string;
  password?: string;
  newPassword?: string;
  oldPassword?: string;
  coursesName?: string;
}

export class Validator {

  static validate<T extends object>(obj: T, required: (keyof T)[]): T | null {
    const newErrors: T & Partial<ValidateObjectType> = {} as T & Partial<ValidateObjectType>;

    for (let field in obj) {

      const isRequired = required.includes(field);
      const value = obj[field];

      if (!isRequired && !value) {
        continue;
      }

      const validateMethod = this[field as keyof typeof Validator];

      if (validateMethod && typeof validateMethod === "function") {
        //@ts-ignore
        const error = validateMethod.call(this, value, isRequired);
        if (error) {
          newErrors[field] = error;
        }
      }
    }

    if (obj.hasOwnProperty('repeadPassword') && obj.hasOwnProperty('password') && !newErrors.hasOwnProperty('password') && !newErrors.hasOwnProperty('repeadPassword')) {
      const error = this.checkForPasswordEquality((obj as ValidateObjectType).password || '', (obj as ValidateObjectType).repeadPassword || '');

      if (error) {
        newErrors.repeadPassword = error;
      }
    }

    let isError = this.checkErrorValue<T>(newErrors);

    return isError ? newErrors : null;
  }

  static checkForPasswordEquality(password: string, repeadPassword: string): string | null {
    return password !== repeadPassword ? t('matchPass') : null;
  }

  static getEmptyError(field: string): string {
    return `${t('enter')} ${field}`;
  }

  static getFormatError(field: string): string {
    return `${t('wrongFormat')} ${field}`;
  }

  static checkErrorValue = <T>(objError: T): boolean => {
    for (let fieldErrror in objError) {
      const isErrorValue = objError[fieldErrror];
      if (isErrorValue) {
        return true;
      }
    }

    return false;
  };

  static email(email: string | undefined): string | null {
    if (!email) {
      return this.getEmptyError("Email");
    }

    const pattern = /^[\w.-]+@[\w.-]+\.\w+$/;

    if (!pattern.test(email)) {
      return this.getFormatError("Email");
    }
    return null;
  }

  static phone(phone: string | undefined): string | null {
    if (!phone) {
      return this.getEmptyError(t('phone'));
    }

    if (phone.length < 11) {
      return t('less11chars');
    }

    const pattern =
      /^\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    if (!pattern.test(phone)) {
      return this.getFormatError(t('phoneLc'));
    }

    return null;
  }

  // static inn(inn: string | undefined): string | null {

  //   if (!inn) {
  //     return this.getEmptyError("ИНН");
  //   }

  //   if (inn.length < 10 || inn.length > 12) {

  //     return this.getFormatError("ИНН");
  //   }

  //   return null;
  // }

  static number(number: string | undefined): string | null {
    if (!number) {
      return this.getEmptyError(t('numberLc'));
    }
    return null;
  }

  static langName(langName: string | undefined): string | null {

    if (!langName) {
      return this.getEmptyError('language')
    }

    return null
  }

  static isChecked(isChecked: string | undefined): string | null {

    if (!isChecked) {
      return this.getEmptyError('checked')
    }

    return null
  }

  static blogTitle(blogTitle: string | undefined): string | null {

    if (!blogTitle) {
      return this.getEmptyError('title')
    }

    return null
  }

  static previewDesc(previewDesc: string | undefined): string | null {

    if (!previewDesc) {
      return this.getEmptyError('description')
    }

    return null
  }

  static comment(comment: string | undefined): string | null {

    if (!comment) {
      return this.getEmptyError('comment')
    }

    return null
  }

  static key(key: string | undefined): string | null {

    if (!key) {
      return this.getEmptyError('key')
    }

    return null
  }



  static blogName(blogName: string | undefined): string | null {

    if (!blogName) {
      return this.getEmptyError('name')
    }

    return null
  }


  static phrase(phrase: string | undefined): string | null {

    if (!phrase) {
      return this.getEmptyError('phrase')
    }

    return null
  }

  static translatedPhrase(translatedPhrase: string | undefined): string | null {

    if (!translatedPhrase) {
      return this.getEmptyError('Translation of the phrase')
    }

    return null
  }


  static title(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('titleLc'));
    }
    return null;
  }

  static content(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('descriptionLc'));
    }
    return null;
  }

  static link(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('linkLc'));
    }

    const pattern = /^(http|https|ftp):/;

    if (!pattern.test(value)) {
      return this.getFormatError(t('linksLc'));
    }

    return null;
  }

  static date(date: string | undefined | Date): string | null {
    if (!date) {
      return t('selectDate');
    }

    return null;
  }

  static dateAddToTest(): string | null {
    return null;
  }

  static dateOfTest(date: string | undefined | Date): string | null {
    return this.date(date)
  }

  static username(name: string | undefined): string | null {

    if (!name) {
      return this.getEmptyError(t('nameLc'));
    }
    return null;
  }

  static message(message: string | undefined): string | null {
    if (!message) {
      return this.getEmptyError(t('messageLc'));
    }
    return null;
  }

  static login(value: string | undefined): string | null {

    if (!value) {
      return this.getEmptyError(t('loginLc'));
    }

    if (value && /\s/.test(value)) {
      return t('loginNoSpace')
    }

    return null;
  }

  static password(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('passwordLc'));
    }

    if (value.length < 8) {
      return t('less8chars');
    }

    return null;
  }

  static oldPassword(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('oldPassLc'));
    }

    if (value.length < 8) {
      return t('less8chars');
    }

    return null;
  }

  static newPassword(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('newPassLc'));
    }

    if (value.length < 8) {
      return t('less8chars');
    }

    return null;
  }

  static repeadPassword(repeadPasword: string | undefined): string | null {
    if (!repeadPasword) {
      return t('confirmPassword');
    }

    if (repeadPasword.length < 8) {
      return t('less8chars');
    }

    return null;
  }

  static checkRepead(password: string, repead: string): string | null {
    if (password !== repead) {
      return t('repeatedPassNotMatch');
    }

    return null;
  }

  // static companyName(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите название";
  //   }

  //   return null;
  // }

  // static fio(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите ФИО";
  //   }

  //   return null;
  // }

  // static evidence(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите основание";
  //   }

  //   return null;
  // }

  // static customerTest(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите заказчика испытаний";
  //   }

  //   return null;
  // }

  // static placeTest(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите место проведения";
  //   }

  //   return null;
  // }

  // static supervisorId(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите ID руководителя";
  //   }

  //   return null;
  // }

  // static userId(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите ID пользователя";
  //   }

  //   return null;
  // }

  // static objectTest(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите объект испытаний";
  //   }

  //   return null;
  // }

  static updateAvatar(photo: { id: string, result: string | ArrayBuffer | null, file: File | null }): string | null {
    if (!photo) {
      return t('addPhoto');
    }

    if (photo.result && !photo.file) {
      return t('pickNewPhoto');
    }

    return null;
  }

}
