export const METODATA_TITLES: Record<string, string> = {
  '/dashboard': 'currentAppeals',
  '/users': 'users',
  '/users/add': 'addUser',
  '/feedbacks': 'feedback',
  '/email-templates': 'mailTemplatesTitle',
  '/email-templates/create': 'createMailtemplate',
  '/email-history': 'emailHistory',
  // '/settings': 'settings',
  '/configuration/keys': 'keyConfigurator',
  '/configuration/keys/add': 'keyConfiguratorAdd',
  '/app-logs': 'appLog',
  '/app-statistics': 'appStatistics',
  '/events': 'events',
  '/events/add': 'addEvents',
  // '/common-settings': 'commonSettings',
  '/logs': 'logs',
  '/update-system': 'systemUpdate',
  '/login': 'authorization',
  '/account': 'account',
  //----template--------
  '/languages': 'languages',
  '/phrases': 'phrases',
  '/blogs': 'blogs',
  '/records': 'records',
  '/documents': 'documents'

}

export const METODATA_TITLES_DINAMIC: Record<string, string> = {
  '/users/info/': 'userInformation',
  '/users/edit/': 'editUser',
  '/feedbacks/edit/': 'editReview',
  '/email-templates/edit/': 'updateMailTemplate',
  '/events/edit/': 'editingEvent',
  //----template--------

  '/languages/edit/': 'editLang',
  '/phrases/add': 'addPhrase',
  '/phrases/info': 'phrase',
  '/phrases/edit': 'editPhrase',
  '/blogs/add': 'blogAdd',
  '/blogs/edit/': 'blogEdit',
  '/blogs/info/': 'blog',
  '/blogs/info/data/': 'blog',
  '/blogs/info/data/add/': 'blogAdd',
  '/blogs/info/data/edit/': 'blogEdit',
  '/records/edit/': 'record',
  '/records/info/': 'record',
  '/documents/info/': 'documents',
  '/documents/add': 'addDocument',
  '/documents/edit/': 'editDocument'
}
