import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import enData from './data/en.json'
import ruData from './data/ru.json'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: true,
    resources: {
      en: {
        translation: enData
      },
      ru: {
        translation: ruData
      }
    }
  })

i18next.changeLanguage(APP_LANGUAGE)


