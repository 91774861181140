import { lazy } from "react";

export const AccountPage = lazy(() => import("./Account/Account"));
export const DashboardPage = lazy(() => import("./Dashboard/Dashboard"));
export const UserInfoPage = lazy(() => import("./User/user-info/UserInfo"));
export const UserEditPage = lazy(() => import("./User/user-edit/UserEdit"));
export const UserAddPage = lazy(() => import("./User/user-add/UserAdd"));
export const UserListPage = lazy(() => import("./User/UserList"));
export const FeedBackEditPage = lazy(() => import("./FeedBacks/FeedBackEdit"));
export const FeedBackListPage = lazy(() => import("./FeedBacks/FeedBackList"));
export const EmailTemplateListPage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateList")
);
export const EmailHistoryListPage = lazy(
  () => import("./Templates/Email-Hstory/EmailHistoryList")
);
export const EmailTemplateCreatePage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateCreate")
);
export const EmailTemplateEditPage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateEdit")
);
export const AppLogsPage = lazy(() => import("./AppWork/AppLogs"));
export const AppStatisticsPage = lazy(
  () => import("./AppStatistics/AppStatistics")
);
export const AppStatisticsEventsListPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsList")
);
export const AppStatisticsEventsAddPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsAdd")
);
export const AppStatisticsEventsEditPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsEdit")
);
export const LogsPage = lazy(() => import("./Systems/Logs/Logs"));
export const CommonSettingsPage = lazy(
  () => import("./Systems/CommonSettings/CommonSettings")
);
export const UpdateSystemPage = lazy(
  () => import("./Systems/UpdateSystem/UpdateSystem")
);
export const SettingsExamplPage = lazy(
  () => import("./Settings/SettingsExampl")
);
export const ConfigurationKeysListPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysList")
);
export const ConfigurationKeysAddPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysAdd")
);
export const ConfigurationKeysEditPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysEdit")
);
export const LoginPage = lazy(() => import("./Login/Login"));
//------Template---------

export const TaskAddPage = lazy(() => import("./Task/TaskAdd"));
export const TaskEditPage = lazy(() => import("./Task/TaskEdit"));
export const LanguagesPage = lazy(() => import('./Languages/Languages'))
export const LanguageEditPage = lazy(() => import('./Languages/lang-edit/LanguageEdit'))
export const PhrasesPage = lazy(() => import('./Languages/phrases/Phrases'))
export const PhraseAddPage = lazy(() => import('./Languages/phrases/phrase-add/PhraseAdd'))
export const PhraseEditPage = lazy(() => import('./Languages/phrases/phrase-edit/PhraseEdit'))
export const PhraseInfoPage = lazy(() => import('./Languages/phrases/phrase-info/PhraseInfo'))
export const BlogsPage = lazy(() => import('./Blogs/Blogs'))
export const BlogsAddPage = lazy(() => import('./Blogs/blog-add/BlogAdd'))
export const BlogsEditPage = lazy(() => import('./Blogs/blog-edit/BlogEdit'))
export const BlogInfoPage = lazy(() => import('./Blogs/blog-info/BlogInfo'))
export const BlogDataInfoPage = lazy(() => import('./Blogs/blog-data-info/BlogDataInfo'))
export const BlogDataInfoAdd = lazy(() => import('./Blogs/blog-data-add/BlogDataAdd'))
export const BlogDataInfoEdit = lazy(() => import('./Blogs/blog-data-edit/BlogDataEdit'))
export const RecordsPage = lazy(() => import('./Records/Records'))
export const RecordEditPage = lazy(() => import('./Records/record-edit/RecordEdit'))
export const RecordInfoPage = lazy(() => import('./Records/record-info/RecordInfo'))
export const DocumentsPage = lazy(() => import('./Documents/Documents'))
export const DocumentsInfoPage = lazy(() => import('./Documents/documents-info/DocumentsInfo'))
export const DocumentsAddPage = lazy(() => import('./Documents/document-add/DocumentAdd'))
export const DocumentsEditPage = lazy(() => import('./Documents/document-edit/DocumentEdit'))
